import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import ColumnBlock from "./ColumnBlock";
import Hero from "./Hero";
import { GatsbyImage } from "gatsby-plugin-image";

const options = (smallLayout) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-semibold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className=" text-4xl sm:text-5xl py-8 mt-6 font-medium">
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h1 className="text-2xl font-semibold my-4">{children}</h1>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h1 className="text-xl font-semibold my-4">{children}</h1>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h1 className="text-lg font-semibold my-4">{children}</h1>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="py-4 max-w-screen-md">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => children,
    [BLOCKS.LIST_ITEM]: (node) => (
      <li className="">{node.content[0].content[0].value}</li>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
          <div className="text-center">
            <GatsbyImage image={node.data.target.gatsbyImageData} alt="bild" />
          </div>
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (node.data.target["__typename"] === "ContentfulColumnBlocks") {
        return (
          <div
            className={`flex flex-wrap lg:-mx-0 mt-6 max-w-screen-${
              smallLayout ? "md" : "lg"
            }`}
          >
            {node.data.target.blocks.map((block, i) => (

              <ColumnBlock index={i} link={(block.linkDe ? block.linkDe:"")+(block.linkEn ? block.linkEn:"")+(block.linkSv ? block.linkSv:"")}>

                {block.bodyDe &&
                  renderRichText(block.bodyDe, optionsColumnBlock)}
                {block.bodyEn &&
                  renderRichText(block.bodyEn, optionsColumnBlock)}
                {block.bodySv &&
                  renderRichText(block.bodySv, optionsColumnBlock)}
              </ColumnBlock>
            ))}
          </div>
        );
      }
      if (node.data.target["__typename"] === "ContentfulHero") {
        const hero = node.data.target;
        return (
          <div
            className="relative -mt-12 z-0"
            style={{
              marginLeft: "-50vw",
              width: "100vw",
              left: "50%",
            }}
          >
            <div className="md:hidden">
            <Hero image={hero.imagesm}>
              {hero.bodyDe && renderRichText(hero.bodyDe, optionsHero)}
              {hero.bodyEn && renderRichText(hero.bodyEn, optionsHero)}
              {hero.bodySv && renderRichText(hero.bodySv, optionsHero)}
            </Hero>
            </div>

            <div className="hidden md:block xl:hidden">
              <Hero image={hero.imagemd}>
                {hero.bodyDe && renderRichText(hero.bodyDe, optionsHero)}
                {hero.bodyEn && renderRichText(hero.bodyEn, optionsHero)}
                {hero.bodySv && renderRichText(hero.bodySv, optionsHero)}
              </Hero>
            </div>
            <div className="hidden xl:block 2xl:hidden">
              <Hero image={hero.imagexl}>
                {hero.bodyDe && renderRichText(hero.bodyDe, optionsHero)}
                {hero.bodyEn && renderRichText(hero.bodyEn, optionsHero)}
                {hero.bodySv && renderRichText(hero.bodySv, optionsHero)}
              </Hero>
            </div>
            <div className="hidden 2xl:block">
              <Hero image={hero.image2xl}>
                {hero.bodyDe && renderRichText(hero.bodyDe, optionsHero)}
                {hero.bodyEn && renderRichText(hero.bodyEn, optionsHero)}
                {hero.bodySv && renderRichText(hero.bodySv, optionsHero)}
              </Hero>
            </div>
          </div>
        );
      }
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <a
        className="font-semibold text-green-800"
        href={node.data.uri}
        target="__blank"
      >
        {children}
      </a>
    ),
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
});

export default function BodyText({ body, smallLayout }) {
  if (!smallLayout)
    return (
      <div className="text-lg leading-relaxed">
        {renderRichText(body, options(smallLayout))}
      </div>
    );
  return (
    <div className="text-lg max-w-screen-md leading-relaxed">
      {renderRichText(body, options(smallLayout))}
    </div>
  );
}

const optionsColumnBlock = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-semibold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="text-5xl font-medium">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h1 className="text-2xl font-semibold">{children}</h1>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h1 className="text-2xl font-semibold pb-2">{children}</h1>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h1 className="text-lg font-semibold">{children}</h1>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h1 className="text-lg font-semibold mt-auto">{children}</h1>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="text-base my-2 ">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => children,
    [BLOCKS.LIST_ITEM]: (node) => (
      <li className="">{node.content[0].content[0].value}</li>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a className="font-semibold text-green-800 " href={node.data.uri}>
        {children}
      </a>
    ),

  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

const optionsHero = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-semibold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="text-4xl lg:text-6xl font-medium py-4 lg:py-10 w-full">
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h1 className="text-2xl font-semibold">{children}</h1>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h1 className="pl-0.5 pb-4 text-xl lg:text-3xl font-light w-full">
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h1 className="text-lg font-semibold">{children}</h1>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h1 className="text-lg font-semibold mt-auto">{children}</h1>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="text-base my-2">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => children,
    [BLOCKS.LIST_ITEM]: (node) => (
      <li className="">{node.content[0].content[0].value}</li>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a className="font-semibold text-green-800 " href={node.data.uri}>
        {children}
      </a>
    ),

  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};
