import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

function renderItem(item) {
  return (
    <div className="w-full h-full">
      <GatsbyImage alt="" image={item.gatsbyImageData} />
    </div>
  );
}

export const ImageCarousel = ({ images }) => {
  return (
    <div className="max-w-screen-md pt-8">
      {images && (
        <ImageGallery
          items={images}
          renderItem={renderItem}
          showPlayButton={false}
          showBullets
          showThumbnails={false}
          useBrowserFullscreen={false}
        />
      )}
    </div>
  );
};

export default ImageCarousel;
