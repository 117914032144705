import React from "react";

const colors = [
  "border-blue-300",
  "border-blue-400",
  "border-blue-500",
  "border-green-500",
  "border-green-600",
    "border-green-700",
    "border-blue-300",
    "border-blue-400",
    "border-blue-500",
    "border-green-500",
    "border-green-600",
    "border-green-700",

];

function ColumnBlock({ children, index, link }) {
  return (

    <div className="w-full sm:w-1/2 md:w-1/3 px-2 mb-8">
        <a href={link !== "" && link} className="">
      <div
        className={`${
            link !== "" && "transform transition ease-in-out delay-10 hover:scale-105 duration-300"} p-4 h-full border-l-8 ${colors[index]} shadow-xl flex flex-col`}
      >
        {children}
      </div>
        </a>
    </div>
  );
}

export default ColumnBlock;
