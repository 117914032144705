import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

function Hero({ image, children }) {
  return (
    <div className="pt-10 z-0 overflow-hidden">
      <div className="md:hidden">
        <GatsbyImage
          className="h-full"
          image={image.gatsbyImageData}
          alt="Hero Image"
          loading="eager"
        />
      </div>
      <div className="flex bg-gradient-to-br from-blue-300 to-blue-100">
        <div className="hidden md:block  md:w-5/6 lg:w-1/2 -mr-20 max-h-120">
          <GatsbyImage
            className="h-full"
            image={image.gatsbyImageData}
            alt="Hero Image"
            loading="eager"
          />
        </div>
        <div className="w-full lg:w-1/2 transform lg:skew-x-12 md:skew-x-10 bg-gradient-to-br from-blue-300 to-blue-100">
          <div className="transform md:-skew-x-10 lg:-skew-x-12 pl-4 lg:pl-20 flex flex-wrap items-center content-center h-full">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
