import { graphql } from "gatsby";
import React from "react";
import BodyText from "../components/BodyText";
import Layout from "../layouts/Layout";
import { ImageCarousel } from "../components/ImageCarousel";
import Kontakt from "../components/Kontakt";
import SiteMetadata from "../components/SiteMetaData";

export default function page({ data, pageContext }) {
  // display contact information (in sidebar) if contactInfo is not set or set to true
  const contactInfo =
    pageContext.contactInfo == null || pageContext.contactInfo;
  return (
    <Layout pageContext={pageContext}>
      <SiteMetadata
        title={pageContext.title}
        description={pageContext.metaDescription}
      />
      <div className="container">
        <div className="max-w-screen-lg 2xl:max-w-screen-xl mx-auto 2xl:mx-0">
          {contactInfo && (
            <LayoutWithSidebar pageContext={pageContext} data={data} />
          )}
          {!contactInfo && (
            <LayoutWithoutSidebar pageContext={pageContext} data={data} />
          )}
        </div>
      </div>
    </Layout>
  );
}

const LayoutWithSidebar = ({ pageContext, data }) => (
  <div className="flex">
    <div className="w-full">
      {/*  Body content */}
      {pageContext.body && (
        <BodyText body={pageContext.body} smallLayout={true} />
      )}
      {/*  Image Slider */}
      <ImageCarousel images={data.contentfulPage[pageContext.imgs]} />
    </div>
    {/*  Contact Information */}
    <div className="md:w-1/3">
      <Kontakt pageContext={pageContext} contactPage={data.contactPage} />
    </div>
  </div>
);

const LayoutWithoutSidebar = ({ pageContext, data }) => (
  <div>
    {/*  Body content */}
    {pageContext.body && (
      <BodyText body={pageContext.body} smallLayout={false} />
    )}
    {/*  Image Slider */}
    <ImageCarousel images={data.contentfulPage[pageContext.imgs]} />
  </div>
);

export const query = graphql`
  query pageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      imagesDe {
        id
        title
        gatsbyImageData
      }
      imagesEn {
        id
        title
        gatsbyImageData
      }
      imagesSv {
        id
        title
        gatsbyImageData
      }
    }
    contactPage: contentfulPage(titleDe: { eq: "Kontakt" }) {
      bodyDe {
        raw
      }
      bodyEn {
        raw
      }
      bodySv {
        raw
      }
    }
  }
`;
