import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

function Kontakt({ pageContext, contactPage }) {
  let userLang = pageContext.pagePath.substring(0, 2);
  /*In case of index  page, inital Language will be wrong for de*/
  if (!["de", "en", "sv"].includes(userLang)) userLang = "de";

  const bodyText = {
    de: contactPage.bodyDe,
    en: contactPage.bodyEn,
    sv: contactPage.bodySv,
  };

  return (
    <div className="pl-14 py-8 mt-10  w-80 hidden xl:block ml-auto">
      {renderRichText(bodyText[userLang], options)}
    </div>
  );
}

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-semibold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="text-3xl font-medium">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h1 className="text-2xl font-semibold my-4">{children}</h1>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h1 className="text-xl font-semibold my-4">{children}</h1>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h1 className="text-lg font-semibold my-4">{children}</h1>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="py-3 max-w-screen-md">{children}</p>
    ),
    [BLOCKS.UL_LIST]: (node, children) => children,
    [BLOCKS.LIST_ITEM]: (node) => (
      <li className="">{node.content[0].content[0].value}</li>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        className="font-semibold text-green-800"
        href={node.data.uri}
        target="__blank"
      >
        {children}
      </a>
    ),
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export default Kontakt;
